<template>
  <div class="tw-flex tw-h-screen tw-flex-col">
    <SiteHeader />
    <div class="tw-flex tw-flex-auto tw-flex-col tw-content-start tw-items-center tw-px-6 tw-py-14">
      <div class="tw-w-full tw-max-w-lg">
        <div class="tw-mb-8 tw-space-y-2">
          <Heading :level="3"><slot name="header" /></Heading>
          <Subheading><slot name="sub-header" /></Subheading>
        </div>
        <slot />
      </div>
    </div>
    <SiteFooter />
    <GetHelp />
    <AppToast />
  </div>
</template>

<script setup lang="ts">
// Use the composable for dynamic head
const { applyHead } = useDynamicHead()
applyHead()
</script>
